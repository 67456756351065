<template>
  <div class="tc-data-backup">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="interviewBtn" @click="showLocalBox">
        <img src="../../../assets/images/localData.png" height="43" alt />
      </div>
      <div class="interviewBtn" @click="showLocalRestorebox">
        <img src="../../../assets/images/dataRecover.png" height="43" alt />
      </div>
      <div class="interviewBtn" @click="showCustombox">
        <img src="../../../assets/images/custom.png" height="43" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        border
        class="tableBox"
        v-loading="loading"
      >
        <el-table-column type="index" width="60" align="center"></el-table-column>
        <el-table-column prop="dataName" label="数据库名称" width="500" align="center"></el-table-column>
        <el-table-column prop="full" label="数据完整性" width="160" align="center"></el-table-column>
        <el-table-column prop="state" label="应用状态" width="160" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.state == '未启用'?'off':'no'">{{scope.row.state}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="备份日期" width="250" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="shoWEditBox(scope.row)">编辑</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="down(scope.row)">下载</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="restore(scope.row)">还原</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改-模态框 -->
    <el-dialog title="编辑数据库" :visible.sync="editBox" width="30%" center>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="数据库名称:">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="upName">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 本机数据备份-模态框 -->
    <el-dialog title="本机数据备份" :visible.sync="localBox" width="30%">
      <el-form ref="local" :model="local" label-width="130px" size="mini">
        <el-form-item label="当前数据库：">
          <span>{{isName}}</span>
        </el-form-item>
        <el-form-item label="数据库名称：">
          <el-input v-model="local.name"></el-input>
        </el-form-item>
        <el-form-item label="数据库描述：">
          <el-input type="textarea" v-model="local.content" :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="localBox = false" v-show="isbackupBtn">取 消</el-button>
        <el-button type="primary" @click="backup" :disabled="!isbackupBtn">
          <span v-if="isbackupBtn">确 定</span>
          <span v-if="!isbackupBtn">
            <span>正在备份</span>
            <i class="el-icon-loading"></i>
          </span>
        </el-button>
      </span>
    </el-dialog>
    <!-- 本机数据恢复-模态框 -->
    <el-dialog title="数据恢复" :visible.sync="localRestorebox" width="30%" center>
      <el-upload
        class="upload-demo"
        ref="upload"
        action="/upload_backup"
        :on-success="fileUploadApi"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
        :limit="1"
      >
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
        >上传到服务器</el-button>
        <span slot="tip" class="el-upload__tip" style="margin-left:30px">注意：只能上传 .sql 文件</span>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="localRestorebox = false">取 消</el-button>
        <el-button type="primary" @click="localRestorebox = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 自定义模态框 -->
    <el-dialog title="自定义备份数据库" :visible.sync="customBox" width="30%" center>
      <el-form ref="form" :model="form" label-width="200px">
        <el-form-item label="自动备份数据库日期:">
          <el-select v-model="form.customDate" placeholder="请选择">
            <el-option v-for="index in 31 " :key="index" :label="index + '号'" :value="index"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customBox = false">取 消</el-button>
        <el-button type="primary" @click="upCustom">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { dataIndex, selectConfig, updateConfig } from "@/api/comm.js";
import moment from "moment";
export default {
  inject: ["reload"],
  data() {
    return {
      loading: true,
      tableData: [],
      form: {
        name: "",
        customDate: ""
      },
      local: {
        name: "",
        content: "",
        tp: "backup"
      },
      inName: "",
      editBox: false,
      localBox: false,
      localRestorebox: false,
      customBox: false,
      isName: "",
      fileList: [],
      isbackupBtn: true
    };
  },
  created() {
    this.loadDataList();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 加载列表
    loadDataList() {
      let data = {
        tp: "all"
      };
      let arr = [];
      dataIndex(data).then(res => {
        if (res.code == 400200) {
          this.loading = false;
          let tableData2 = res.data;
          for (let k in tableData2) {
            tableData2[k].dataName = k;
            arr.push(tableData2[k]);
            // if (tableData2[k].state == "已启用") {
            //   this.isName = k;
            // }
          }
          this.tableData = arr;
        }
      });
    },
    // 显示编辑模块
    shoWEditBox(row) {
      //console.log(row);
      this.form.name = row.dataName;
      this.inName = row.dataName;
      this.editBox = true;
    },
    // 编辑提交
    upName() {
      let data = {
        tp: "alter",
        name: this.inName,
        key_name: this.form.name
      };
      dataIndex(data).then(res => {
        if (res.code === 400200) {
          this.$message({
            message: "修改成功",
            type: "success"
          });
        }
        this.loadDataList();
        this.editBox = false;
      });
    },
    //显示本机备份
    showLocalBox() {
      this.local.content = "";
      this.localBox = true;
      this.tableData.forEach(v => {
        if (v.state == "已启用") {
          this.isName = v.dataName;
        }
      });
      let date = moment()
        .locale("zh-cn")
        .format("YYYY年MM月DD日HH:mm:ss");
      let item = parseInt(+new Date() / 1000);
      this.local.name = date + "-数据库备份";
    },
    // 数据库备份
    backup() {
      this.isbackupBtn = false;
      dataIndex(this.local).then(res => {
        //console.log(res);
        if (res.code === 400200) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.loadDataList();
          this.localBox = false;
          this.isbackupBtn = true;
        }
      });
    },
    // 下载
    down(row) {
      //console.log(row.dataName);
      let data = {
        tp: "download",
        name: row.dataName
      };
      window.location.href = ` /data_index?tp=download&name=${row.dataName}`;
    },
    //还原
    restore(row) {
      let data = {
        tp: "restore",
        name: row.dataName
      };
      this.$confirm("此操作将还原备份, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          dataIndex(data).then(res => {
            if (res.code === 400200) {
              this.$message({
                message: "还原成功！",
                type: "success"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消还原"
          });
        });
    },
    // 删除
    del(row) {
      let data = {
        tp: "del",
        name: row.dataName
      };
      this.$confirm("此操作将永久删除该备份数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          dataIndex(data).then(res => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功！",
                type: "success"
              });
            }
            this.loadDataList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 显示本机恢复 模态框
    showLocalRestorebox() {
      this.localRestorebox = true;
    },
    // 上传文件监听
    submitUpload(response, file) {
      this.$refs.upload.submit();
    },
    fileUploadApi(response, file) {
      //console.log(response);
      if (response.code === 400200) {
        this.$message({
          message: response.msg,
          type: "success"
        });
        this.fileList = [];
      }
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {
      //console.log(file);
    },
    // 自定义模态框
    showCustombox() {
      this.customBox = true;
      selectConfig({ config_type: "system_sql" }).then(res => {
        this.form.customDate = parseInt(res.data.backup_time);
      });
    },
    // 自定义模态框提交按钮
    upCustom() {
      updateConfig({ backup_time: this.form.customDate }).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "保存成功",
            type: "success"
          });
          this.customBox = false;
        }
      });
    }
  }
};
</script>
<style lang="less">
.tc-data-backup {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
}
</style>